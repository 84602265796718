@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,400&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.app-nav-container {
  @apply max-w-6xl mx-auto p-4 sm:p-6;
}
.app-main-container {
  @apply max-w-6xl mx-auto px-4 py-4 sm:py-12;
}
.app-modal-width {
  width: min(90vw, 500px);
}
.modal-open {
  overflow: hidden;
}
.switch {
  position: relative;
  display: inline-block;
  height: calc(12px + 2px * 2);
  width: calc(12px * 2 + 2px * 2);
  min-width: calc(12px * 2 + 2px * 2);
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch input:checked + .slider {
  background-color: #333333;
}
.switch input:focus + .slider {
  box-shadow: 0 0 1px black;
}
.switch input:checked + .slider:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #cccccc;
  -webkit-transition: 0.15s;
  transition: 0.15s;

  border-radius: calc(12px + 2px * 2);
}
.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}
button {
  @apply active:scale-90 transition-all duration-200;
}
button:disabled {
  @apply active:scale-100;
}
